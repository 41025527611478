body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  word-break: break-word;
  font-size: 15px;
  font-family: $font-stack-switzer;
  font-style: normal;
  font-weight: normal;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  //margin-bottom: calc(#{$mainContentMargin} / 2);

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin switzer1417_500 {
  font-family: $font-stack-switzer;  
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0em;
}


@mixin switzer1518_500 {
  font-family: $font-stack-switzer;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0em;
}

@mixin switzer1618_500 {
  font-family: $font-stack-switzer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0em;
}


@mixin switzer1618_700 {
  font-family: $font-stack-switzer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0em;
}

@mixin switzer1618_800 {
  font-family: $font-stack-switzer;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  letter-spacing: 0em;
}


@mixin switzer1820_700 {
  font-family: $font-stack-switzer;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0em;
}

@mixin switzer2022_700 {
  font-family: $font-stack-switzer;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0em;
}

@mixin switzer3033_700 {
  font-family: $font-stack-switzer;
  @include size("font-size", 30px);
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0em;
}

@mixin switzer3035_500 {
  font-family: $font-stack-switzer;
  @include size("font-size", 30px);
  font-style: normal;
  font-weight: 500;
  line-height: 116%;
  letter-spacing: 0em;
}

@mixin switzer3035_700 {
  font-family: $font-stack-switzer;
  @include size("font-size", 30px);
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
  letter-spacing: 0em;
}

@mixin switzer4240_800 {
  font-family: $font-stack-switzer;
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: 94%;
  letter-spacing: 0em;
}

@mixin switzer4545_700 {
  font-family: $font-stack-switzer;
  @include size("font-size", 45px);
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0em;
}

@mixin switzer6050_800 {
  font-family: $font-stack-switzer;
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  line-height: 83%;
  letter-spacing: 0em;
}

@mixin switzer8075_800 {
  font-family: $font-stack-switzer;
  @include size("font-size", 80px);
  font-style: normal;
  font-weight: 800;
  line-height: 94%;
  letter-spacing: 0em;
}

@mixin switzer190_158 {
  font-family: $font-stack-switzer;
  @include size("font-size", 190px);
  font-style: normal;
  font-weight: 800;
  line-height: 83%;
  letter-spacing: 0em;
}

@mixin switzer202_168 {
  font-family: $font-stack-switzer;
  @include size("font-size", 202px);
  font-style: normal;
  font-weight: 800;
  line-height: 83%;
  letter-spacing: 0em;
}