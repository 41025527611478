$contentWrapperWidth: 1160px;
$mainContentMarginMobile: 10px;
$mainContentMargin: 20px;
$contentMaxWidth: 700px;
$contentInnerWidth: 676px;

html {
  --sizer: 0.7;
}
$sizeFactor: var(--sizer);
$mainWidth: calc(1640px * #{$sizeFactor}) ;

$mq-breakpoints: (
  mobile: 320px,
  tablet: 780px,
  desktop: 980px,
  wrapper: $contentWrapperWidth,
  desktop-wide: 1200px,
  wide: 1300px,
  // Tweakpoints
  //desktopAd: 810px,
  //mobileLandscape: 480px,
);

//$mq-show-breakpoints: (mobile, tablet, desktop, wrapper, wide);