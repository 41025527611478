$font-stack-switzer: "Switzer";

  @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 100;
    font-display: swap;
    src: url("./fonts/Switzer-Thin.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-Thin.woff?v=3.19") format("woff");
  }

  @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 100;
    font-display: swap;
    src: url("./fonts/Switzer-ThinItalic.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-ThinItalic.woff?v=3.19") format("woff");
  }
  
  @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 200;
    font-display: swap;
    src: url("./fonts/Switzer-Extralight.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-Extralight.woff?v=3.19") format("woff");
  }
  @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 200;
    font-display: swap;
    src: url("./fonts/Switzer-ExtralightItalic.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-ExtralightItalic.woff?v=3.19") format("woff");
  }
  
  @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 300;
    font-display: swap;
    src: url("./fonts/Switzer-Light.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-Light.woff?v=3.19") format("woff");
  }
  @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 300;
    font-display: swap;
    src: url("./fonts/Switzer-LightItalic.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-LightItalic.woff?v=3.19") format("woff");
  }
  
  @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Switzer-Regular.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-Regular.woff?v=3.19") format("woff");
  }
  @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Switzer-Italic.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-Italic.woff?v=3.19") format("woff");
  }
  
  @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("./fonts/Switzer-Medium.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-Medium.woff?v=3.19") format("woff");
  }
  @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 500;
    font-display: swap;
    src: url("./fonts/Switzer-MediumItalic.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-MediumItalic.woff?v=3.19") format("woff");
  }
  
  @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("./fonts/Switzer-Semibold.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-Semibold.woff?v=3.19") format("woff");
  }
  @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 600;
    font-display: swap;
    src: url("./fonts/Switzer-SemiboldItalic.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-SemiboldItalic.woff?v=3.19") format("woff");
  }
  
  @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("./fonts/Switzer-Bold.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-Bold.woff?v=3.19") format("woff");
  }
  @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 700;
    font-display: swap;
    src: url("./fonts/Switzer-BoldItalic.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-BoldItalic.woff?v=3.19") format("woff");
  }
  
  @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 800;
    font-display: swap;
    src: url("./fonts/Switzer-Extrabold.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-Extrabold.woff?v=3.19") format("woff");
  }
  @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 800;
    font-display: swap;
    src: url("./fonts/Switzer-ExtraboldItalic.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-ExtraboldItalic.woff?v=3.19") format("woff");
  }
  
  @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 900;
    font-display: swap;
    src: url("./fonts/Switzer-Black.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-Black.woff?v=3.19") format("woff");
  }
  @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 900;
    font-display: swap;
    src: url("./fonts/Switzer-BlackItalic.woff2?v=3.19") format("woff2"),
         url("./fonts/Switzer-BlackItalic.woff?v=3.19") format("woff");
  }