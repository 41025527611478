@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin responsive-ratio($x,$y, $pseudo: false) {
	$padding: unquote( ( $y / $x ) * 100 + '%' );
	position: relative;	

	@if $pseudo {
		&::before {
			@include pseudo($pos: relative);
			//width: 100%;
			padding-top: $padding;
		}
	} @else {
		padding-top: $padding;
	}

	img, iframe {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	img {
		object-fit: contain;
	}
}

@mixin size($property, $size) { // element spacing
	#{$property}: calc( #{$size} * #{$sizeFactor} ) ;
}

@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
    @include pseudo($pos: $position);
    width: 0;
    height: 0;
    @if $round {
        border-radius: 3px;
    }
    @if $direction == down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round( $size / 2.5 );
    } @else if $direction == up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round( $size / 2.5 );
    } @else if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    } @else if  $direction == left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}