.slide-3 {
    background-color: $grey-one;
    padding: 20px 16px 24px 16px;
    margin-bottom: 24px;
    border-radius: 16px;

    @include mq($from: tablet) {
        @include size('padding-top', 48px);
        @include size('padding-bottom', 48px);
        @include size('padding-left', 48px);
        @include size('padding-right', 48px);
        @include size('border-radius', 48px);
        margin-bottom: 120px;
    }

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);

    &.scrolled {
        transition: top 250ms;        
    }

    .inner {
        width: calc(100% - 32px);
        position: relative;
        //

        @include mq($from: tablet) {
            width: 70%;
        }

        p {
            @include switzer1618_500;
            margin-bottom: 18px;

            @include mq($from: tablet) {
                @include switzer3035_500;
                margin-bottom: 28px;
            }

            strong {
                @include switzer1618_700;

                @include mq($from: tablet) {
                    @include switzer3035_700;
                }
            }

            a {
                text-decoration: underline;
            }
        }
    }

    .arrow-up {
        cursor: pointer;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        transform: rotateZ(-90deg);
        transform-origin: bottom left;
        background-color: $black;
        color: $white;

        width: 260px;
        right: -294px;
        bottom: 0;
        padding-top: 6px;
        padding-bottom: 7px;
        padding-left: 16px;
        padding-right: 16px;
        height: 28px;

        @include switzer1618_700;

        @include mq($from: tablet) {
            @include switzer3035_700;
            @include size('width', 400px);
            @include size('right', -600px);
            @include size('bottom', 0px);
            @include size('padding-top', 10px);
            @include size('padding-bottom', 14px);
            @include size('padding-left', 16px);
            @include size('padding-right', 12px);
            @include size('height', 60px);
        }

        &::after {
            position: absolute;
            top: 0;
            left: calc(100%);
            content: '';
            width: 0;
            height: 0;
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-left: 28px solid $black;

            @include mq($from: tablet) {
                @include size('border-top-width', 30px);
                @include size('border-bottom-width', 30px);
                @include size('border-left-width', 74px);                
            }
        }
    }

    .btn__umfrage {
        cursor: pointer;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        transform: rotateZ(-90deg);
        transform-origin: bottom left;
        background-color: $white;
        color: $yellow;
        border: 1.6px solid $red;
        border-radius: 4.6px;
        -webkit-text-stroke-width: 0.48px;
        -webkit-text-stroke-color: $red;
        text-transform: uppercase;

        width: 136px;
        right: -170px;
        bottom: 306px;
        padding-top: 6px;
        padding-bottom: 7px;
        padding-left: 8px;
        padding-right: 8px;
        height: 30px;

        @include switzer1618_800;

        @include mq($from: tablet) {
            display: none;
        }
    }
}