footer {
    @include mq($from: tablet) {
        background-color: $white-clean;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;
    }
}

footer .footer-container {
    display: flex;
    flex-direction: column;    
    @include switzer1417_500;
    padding-bottom: 16px;

    @include mq($from: tablet) {
        display: grid;
        grid-template-columns: minmax(180px, 344px) minmax(180px, 344px) minmax(180px, 344px); 
        @include size('column-gap', 52px);
        @include size('padding-left', 48px);
        @include size('padding-right', 48px);
        width: 100%;
    }

    @include mq($from: desktop) {
        @include switzer1518_500;
    }


    .footer-left {
        border-top: solid 1.2px $black;
        padding-top: 14px;
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;

        @include mq($from: tablet) {
            width: auto;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .footer-center {
        padding-top: 14px;
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;

        @include mq($from: tablet) {
            width: auto;
            border-top: solid 1.2px $black;
            padding-left: 0;
            padding-right: 0;
        }
    }
    
    .footer-right {
        padding-top: 14px;
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;

        @include mq($from: tablet) {
            width: auto;
            border-top: solid 1.2px $black;
            padding-left: 0;
            padding-right: 0;
        }
    }
}