html {
    scroll-behavior: smooth;
}

body {
    background-color: $white-clean;
    color: $black;
}

a {
    color: $black;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: $highlight-color;
    }
}

main, footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 0 8px 8px 8px;

    @include mq($from: tablet) {
        padding: 0 48px 48px 48px;
        @include size('padding-left', 48px);
        @include size('padding-right', 48px);
    }

    .layout {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        width: 100%;

        @include mq($from: desktop) {
            max-width: $mainWidth;
        }
    }
}

.content {
    width: 100%;
    max-width: $contentMaxWidth;
    margin-bottom: 80px;

    p {        
    }

    img {
        width: 100%;
        height: auto;
    }
    
    a {
        text-decoration: underline;
    }
}