$blue: #22333B;
$green: #23F0C7;
$gold: #EFC69B;
$white-text: #EDEDED;

$white: #F5F5F5;

$black: #000000;
$yellow: #FFFF00;
$red: #FF0000;
$white-clean: #FFFFFF;
$grey-one: #F5F5F5;
$highlight-color: $red;