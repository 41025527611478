main .slide-2 {
    background-color: $yellow;
    border-radius: 16px;
    margin-bottom: 24px;
    //padding: 24px 48px;
    //margin-top: 640px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: auto;    

    @include mq($from: tablet) {
        flex-direction: row;
        @include size('height', 572px);
        @include size('border-radius', 48px);
        margin-bottom: 56px;
    }


    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);

    &.scrolled {
        //transition: margin 250ms;        
    }

    .slide-2__left {
        width: 100%;
        padding: 16px;

        @include mq($from: tablet) {
            width: 50%;
            @include size('padding-top', 48px);
            @include size('padding-bottom', 48px);
            @include size('padding-left', 48px);
            @include size('padding-right', 24px);
        }

        .inner {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;

            @include mq($from: tablet) {
                flex-direction: row;
            }

            .button__left {
                background-color: $black;
                color: $yellow;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding-top: 26px;

                width: 100%;
                @include switzer1820_700;

                @include mq($from: tablet) {
                    width: auto;
                    @include switzer4545_700;
                    text-align: left;
                    @include size('padding-top', 36px);
                    @include size('padding-bottom', 48px);
                    @include size('padding-left', 74px);
                    @include size('padding-right', 56px);
                    @include size('height', 176px);                    
                }
            }

            .triangle__right {
                width: 0;
                height: 0;
                border-top: 52px solid  $black;
                border-right: calc((100vw - 48px) / 2) solid transparent;
                border-left: calc((100vw - 48px) / 2) solid transparent;

                @include mq($from: tablet) {
                    width: 0;
                    height: 0;
                    border-top: 1px solid transparent;
                    border-bottom: 1px solid transparent;
                    border-left: 1px solid $black;
                    border-right: none;
                    @include size('border-top-width', 88px);
                    @include size('border-bottom-width', 88px);
                    @include size('border-left-width', 224px);
                }
            }
        }


    }

    .slide-2__right {
        width: 100%;
        padding: 8px 16px 56px 16px;
        position: relative;

        @include mq($from: tablet) {
            width: 50%;
            @include size('padding-top', 48px);
            @include size('padding-bottom', 48px);
            @include size('padding-left', 24px);
            @include size('padding-right', 48px);
        }

        .inner {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .button__right {
                background-color: $white-clean;
                color: $yellow;
                -webkit-text-stroke-width: 1.4px;
                -webkit-text-stroke-color: $red;
                text-transform: uppercase;

                display: block;
                border-radius: 16px;
                border: 9px solid $red;
                text-align: center;
                width: 100%;
                border-width: 4.5px;
                padding: 24px 0px;
                @include switzer4240_800;


                @include mq($from: tablet) {
                    @include switzer8075_800;
                    @include size('-webkit-text-stroke-width', 2.7px);
                    border-radius: 24px;
                    @include size('width', 742px);
                    @include size('border-width', 9px);
                    @include size('padding-top', 40px);
                    @include size('padding-bottom', 40px);
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }

    .slide-2_left__top {
        position: relative;
        width: 100%;
        padding-top: 16px;
        padding-right: 16px;
        padding-left: 16px;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        @include mq($from: tablet) {
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            @include size('padding-top', 24px);
            @include size('padding-left', 48px);
            @include size('padding-right', 24px);

            justify-content: flex-start;
        }

        .inner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            @include switzer1820_700;

            @include mq($from: tablet) {
                justify-content: space-between;
                @include size('width', 742px);
                @include switzer3033_700;
            }

        }

    }


    .slide-2__top {
        position: relative;
        width: 100%;
        padding-top: 8px;
        padding-right: 16px;
        padding-left: 16px;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 40px;

        @include mq($from: tablet) {
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            @include size('padding-top', 24px);
            @include size('padding-left', 24px);
            @include size('padding-right', 48px);
            margin-bottom: 0;
        }

        .inner {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;

            @include switzer1820_700;

            @include mq($from: tablet) {
                @include size('width', 742px);
                @include switzer3033_700;
            }

            .slide-2__top__left {
    
            }
    
            .slide-2__top__right {
                @include size('margin-right', 9px);
                @include switzer1820_700;

                @include mq($from: tablet) {
                    @include switzer3033_700;
                    font-weight: 500;
                }

                font-weight: 500;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-end;
                align-self: flex-end;
                
                .active {
                    font-weight: 700;
                }
            }
        }

    }    

}