main .slide-1 {
    //position: fixed;

    padding: 16px 16px 16px 16px;

    @include mq($from: tablet) {
        @include size('padding-top', 24px);
        @include size('padding-bottom', 48px);
        @include size('padding-left', 48px);
        @include size('padding-right', 48px);
    }

    h1 {
        @include switzer6050_800;
        color: $yellow;
        -webkit-text-stroke-width: 2.2px;
        -webkit-text-stroke-color: $red;
        text-transform: uppercase;

        @include mq($from: tablet) {
            @include switzer190_158;
            @include size('-webkit-text-stroke-width', 6.7px);
        }

        @include mq($from: desktop-wide) {
            @include switzer202_168;
        }

        &.mobile {
            display: block;

            @include mq($from: tablet) {
                display: none;
            }
        }

        &.desktop {
            display: none;

            @include mq($from: tablet) {
                display: block;
            }
        }
    }


    .logos {
        position: absolute;
        top: 16px;
        right: 0;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        @include mq($from: tablet) {
            @include size('height', 87px);
            right: 0;
            top: 24px;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
        }


        > a {
            display: block;
            margin-left: 20px;

            .bfsb {
                width: auto;
                height: 37px;

                @include mq($from: tablet) {
                    @include size('height', 87px);
                }
            }

            .krb {
                width: auto;
                height: 41px;

                @include mq($from: tablet) {
                    @include size('height', 75px);
                }
            }
        }
    }

    &.scrolled {
        position: fixed;
        -webkit-backface-visibility:hidden;
        -webkit-transform: translateZ(0);
        left: 8px;
        top: 0;
        right: 8px;
        width: calc(100% - 16px);

        @include mq($from: tablet) {
            top: 0;
            //@include size('left', 48px);
            //@include size('right', 48px);
            left: auto;
            right: auto;
            width: calc( 100% - (96px * var(--sizer)) );
        }

        .logos {
            @include mq($from: tablet) {
                @include size('padding-right', 0px);
            }
        }
    }
}